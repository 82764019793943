<template>
	<div class="default-body">
		<div class="default-head">
			<MainHead></MainHead>
		</div>
        <el-row v-if="this.user.ID>0 && $route.path !='/login'">
            <el-container>
                <el-aside class="body-left" width="200px" style="overflow-y:hidden">
                    <el-row style="margin-bottom: 10px;width: 100%;">
                      <el-select v-model="value" placeholder="select Language" style="width: 100%;" @change="setCookie">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-row> 
                    <el-row class="menu-left">
                      <el-col >
                        <el-tag effect="dark" class="body-left-title">{{$t('msg102')/*管理選單*/}}</el-tag>
                        <el-menu class="body-left-menu" >
                            <el-submenu index="1">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n8')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[1]" :key="index2">
                                    <el-menu-item :index="'1-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu>
<!--                            <el-submenu index="9">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n9')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[9]" :key="index2">
                                    <el-menu-item :index="'9-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu> -->
                            <el-submenu index="2">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n7')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[2]" :key="index2">
                                    <el-menu-item :index="'2-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu>
                            <el-submenu index="3">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n2')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[3]" :key="index2">
                                    <el-menu-item :index="'3-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu>
                            <el-submenu index="4">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n3')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[4]" :key="index2">
                                    <el-menu-item :index="'4-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu>
<!--                            <el-submenu index="5">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n6')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[5]" :key="index2">
                                    <el-menu-item :index="'5-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu> -->
<!--                            <el-submenu index="6">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n5')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[6]" :key="index2">
                                    <el-menu-item :index="'6-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu> -->
<!--                            <el-submenu index="7">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n4')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[7]" :key="index2">
                                    <el-menu-item :index="'7-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu> -->
<!--                            <el-submenu index="8">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.n1')}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[8]" :key="index2">
                                    <el-menu-item :index="'8-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu> -->
<!--                            <el-submenu :index="index" v-for="(item, index) in config.menu_type" :key="index">
                                <template slot="title">
                                    <i class="el-icon-hot-water icon"></i>
                                    {{$t('MenuType.'+item)}} {{item}} {{index}}
                                </template>
                                <span v-for="(item2, index2) in user.Router[index]" :key="index2">
                                    <el-menu-item :index="index +'-'+ index2"  @click="go2page(item2.RouterPath)" v-if="item2.RouterName">
                                        <span class="main-subtitle">{{$t('SystemMenu.'+item2.RouterName)}}</span>
                                    </el-menu-item>
                                </span>
                            </el-submenu> -->
                            <el-menu-item index="99" @click="Logout">
                                <i class="el-icon-switch-button icon"></i> <span class="main-subtitle">{{$t('msg103')/*登出*/}}</span>
                            </el-menu-item>
                         </el-menu>
                      </el-col>
                    </el-row>
                </el-aside>
                <el-main class="default-content">
                    <el-row  style="margin-top: -20px;margin-bottom: 15px;height: 32px;">
                        <div v-if="user.Router ">
                            <el-col v-if="user.Router['12'] ">
                                <transition name="el-fade-in-linear">
                                    <span v-if="ShowPaymentChk == true">
                                        <el-tag type="success" @click="go2page('/business/Recharge/RechargeList')" style="cursor: pointer;">{{ RechargeTotal+" "+$t('msg126') }}</el-tag> 
                                        &nbsp;&nbsp;
                                        <el-tag type="warning" @click="go2page('/business/Cash/CashList')" style="cursor: pointer;">{{  CashTotal +" "+$t('msg127') }}</el-tag>
                                    </span>
                                </transition>
                            </el-col>
                        </div>
                    </el-row>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-row>
        <el-row v-if="$route.path =='/login'">
            <el-main class="default-content">
                <router-view></router-view>
            </el-main>
        </el-row>
		<div class="default-foot">
			<MainFoot></MainFoot>
		</div>
	</div>
</template>
<script>
import { apiCheckLogin,apiLogout,apiPaymentChk } from "@/api.js";
import MainHead from '@/components/main-head.vue'
import MainFoot from '@/components/main-foot.vue'
import { mapGetters } from "vuex";
import { globalMixin } from '@/mixin.js';
export default {
    mixins: [globalMixin],
    data() {
        return {
            RechargeTotal:0,
            CashTotal:0,
            ShowPaymentChk:false,
            value: '',
            options: [{
              value: 'zh-TW',
              label: '繁體中文'
            }, {
              value: 'cn',
              label: '简体中文'
            }, {
              value: 'vi-VN',
              label: 'Tiếng Việt'
            }, {
              value: 'en',
              label: 'English'
            }, ],
        }
    },
	components: {
		MainHead,
		MainFoot
	},
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
    },
    methods: {
        CloseSPK()
        {
            this.ShowPaymentChk = false
        },
        PaymentChk()
        {
            clearInterval(this.CD);
          apiPaymentChk({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.$router.push("/login");
                    }
                    if(response.data.status)
                    {
                        this.ShowPaymentChk = true
                        this.RechargeTotal = response.data.data.RechargeTotal
                        this.CashTotal = response.data.data.CashTotal
                        this.CD = setInterval(() => {
                            this.PaymentChk();
                        }, 15000);
                        this.CSPK = setInterval(() => {
                            this.CloseSPK();
                        }, 5000);
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        checkLogin()
        {
            apiCheckLogin({
                })
                .then(response => {
                    if(!response.data.status)
                    {
                        this.$router.push("/login");
                    }
                    if(response.data.status)
                    {
                        this.$store.dispatch('setUserInfo',response.data.data);
                        this.login_path=false;
                        if(this.user.Router["12"])
                        {
                            this.PaymentChk()
                        }
                    }
                    return;
                }).catch(error => {
                    console.log(error);
                    return;
                });
        },
        Logout()
        {
            apiLogout({
            })
            .then(response => {
                if(response.data.status)
                {
                    this.message(this.$t('msg81'));
                    this.$store.dispatch('clearUserInfo');
                    this.login_path=true
                    this.$router.push("/login");
                }else
                {
                    this.message(response.data.data.message);
                }
                return;
            }).catch(error => {
                console.log(error);
                return;
            });
        }
    },
    created() {
        if(this.user.ID==0 && this.$route.path !="/login")
        {
            this.checkLogin()
        }
    },
    destroyed: function()
	{
        clearInterval(this.CD);
        clearInterval(this.CSPK);
    }   
}
</script>

<style lang="stylus" rel="stylesheet/stylus">
.default-body
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    bottom 0px
    background-color: $main-bg-color;
.mainSearch
    table
        table-layout:fixed;
    .tr-total
        background: #4d4d4d;
        color: #efefef;
    .mainTable
        width 100%
        .td-long-head
            width: 120px
        .td-head
            padding 10px
        .td-content
            padding 5px
            font-size: 14px;
        .tr-color
            background-color $td-bg-color
        tr:hover
            background-color $td-bg-hover-color
            color: #606266;
        .td-min
            width 70px
        .td-min2
            width 90px
        .td-date
            width 120px
        .td-max
            width 500px
        .content-subtitle
            white-space : nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
.Dashboard
    .Dashboard-item
        padding 15px 20px
    .Dashboard-item-text
        padding 5px 15px
    .Dashboard-total
        background-color $dashboard-bg-total-color
        color $dashboard-font-total-color
    .Dashboard-in-total
        background-color $dashboard-in-total-color
        color $dashboard-font-total-color
    .Dashboard-out-total
        background-color $dashboard-out-total-color
        color $dashboard-font-total-color
.form-item
    padding 0px 10px
.form-info
    color $font-green-color
    border: 1px $font-green-color solid;
    border-radius: 4px;
    padding: 0px 10px;
.body-left-title
    width 100%
    text-align center
    font-size 16px
.mainPage
    width 100%
    text-align: center;
.no-record
    text-align: center;
    padding 20px
    .no-record-txt
        padding 10px
.dialog-footer
    text-align center
.default-foot
    width 100%
.time-icon
    color $time-icon-color
.click-link
    border-bottom 1px solid #5A9EEC
    padding-bottom 5px
    cursor: pointer;
.Dialog-Table
    padding: 5px
.link-click
    border-bottom: 1px solid;
    color: #290bf2;
    cursor: pointer;
</style>